import React, { useState } from 'react';
import { UseStyles } from 'hooks';
import Button from '@mui/material/Button';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import CircularProgress from '@mui/material/CircularProgress';
import { useStoreon } from 'storeon/react';
import { useDialog } from 'hooks/useDialog';

export interface ComponentProps {
  name: string;
  title: string;
  buttonLabel?: string;
  cancelLabel?: string;
  buttons?: Array<{
    label: string;
    action?: 'close' | 'confirm' | 'open_dialog';
    variant: 'contained' | 'outlined';
    dialog_name?: string;
    onClick?: () => void;
  }>;
  subTitle?: string | React.ReactNode;
  description: string | React.ReactNode;
  successMessage?: string;
  // successMessageType?: 'toast' | 'popup';
  handle: () => void;
  failureMessage?: string;
  onSuccess?: () => void;
  noCancel?: boolean;
}

export function ConfirmDialog({
  name,
  title,
  handle,
  buttons,
  buttonLabel,
  cancelLabel,
  subTitle,
  description,
  successMessage,
  failureMessage,
  onSuccess,
  noCancel,
}: ComponentProps): JSX.Element {
  const classes = UseStyles();
  const [submitting, setSubmitting] = useState(false);
  const { dispatch } = useStoreon();
  const { isOpen, close } = useDialog(name);
  const handleConfirm = async () => {
    try {
      setSubmitting(true);
      await handle();
      !!onSuccess && (await onSuccess());
      !!successMessage && dispatch('message/open', successMessage);
    } catch (e) {
      console.log({ e });
      !!failureMessage && dispatch('message/error', failureMessage);
      throw e;
    } finally {
      close();
      setSubmitting(false);
    }
  };
  return (
    <DialogDefault open={isOpen} onClose={close} title={title}>
      {!!subTitle && <div className={classes.accountDialogContentSubTitle}>{subTitle}</div>}
      <div>{description}</div>
      {!!buttons && (
        <div className={`${classes.accountDialogActions} ${classes.accountDialogActionsRow} dialog_actions`}>
          {buttons.map(({ label, action, variant, dialog_name, onClick }) => (
            <Button
              key={label}
              disabled={'confirm' === action && submitting}
              color={'contained' === variant ? 'primary' : 'primary'}
              onClick={
                onClick
                  ? onClick
                  : 'open_dialog' === action
                  ? () => dispatch('dialog/open', { id: dialog_name })
                  : 'close' === action
                  ? close
                  : handleConfirm
              }
              variant={variant}
            >
              {label}
              {'confirm' === action && submitting && <CircularProgress size={24} />}
            </Button>
          ))}
        </div>
      )}
      {!buttons && (
        <div className={classes.accountDialogActions}>
          <Button disabled={submitting} onClick={handleConfirm} color='primary' variant='contained'>
            {buttonLabel || 'Confirm'}
            {submitting && <CircularProgress size={24} />}
          </Button>
          {!noCancel && <Button onClick={close}>{cancelLabel || 'Cancel'}</Button>}
        </div>
      )}
    </DialogDefault>
  );
}
