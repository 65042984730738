import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import { PlanInterface } from 'api/userEnrollInfo';
import { ClassNameMap } from '@mui/styles/withStyles';
// import { getConvertedPrice } from 'utils';
import plansList from 'data/plans.json';
import { PurchaseBriefPlanOutputTypeEnum } from 'services/api/client';
import { PlanPrice } from './PlanPrice';
import { ensureText } from 'utils';

type Props = {
  classes: ClassNameMap;
  plan: PlanInterface;
  featured?: boolean;
  setSelected: (id: string | undefined) => void;
};

export function Plan({ plan, featured, classes, setSelected }: Props): JSX.Element {
  const { id, title, note, period, inactive, trial, type, selected } = plan;
  const plans = plansList as Record<PurchaseBriefPlanOutputTypeEnum, any>;
  const periodType = `${period.number}${period.unit}` as PurchaseBriefPlanOutputTypeEnum;
  const planConfig = type && period ? plans[periodType] || plans[type] : null;
  const onClick = () => {
    if (inactive) return;
    setSelected(id);
  };
  const benefits =
    'year' === period?.unit && !trial
      ? `*Eligible for all ${ensureText('WeSalute+')} add-on benefits`
      : '*Eligible for additional household members.<br />Not eligible for travel protection add-on.';
  return (
    <div className={`${classes.col} ${classes.colOneThird}`}>
      <div
        className={`${classes.pageOtpCard} ${selected ? classes.pageOtpCardActive : ''} ${
          featured && !!planConfig?.featured ? classes.pageOtpCardFeatured : ''
        } ${inactive ? classes.pageOtpCardInActive : ''}`}
      >
        <div className={classes.pageOtpCardInner}>
          <div className={classes.pageOtpCardContent}>
            {featured && !!planConfig?.featured && (
              <div className={classes.pageOtpCardBestValue}>
                <StarIcon fontSize='inherit' className='icon' />
                {planConfig.featured}
              </div>
            )}
            <div className={classes.pageOtpCardTitle}>{ensureText(planConfig?.title || title, true)}</div>
            {!!planConfig?.old_price && <div className={classes.pageOtpCardOldPrice}>${planConfig.old_price}</div>}
            <div className={classes.pageOtpCardPrice}>
              <PlanPrice plan={plan} />
            </div>
            <div
              className={classes.pageOtpCardNote}
              dangerouslySetInnerHTML={{ __html: ensureText(note as string, true) }}
            />
            <Button
              className={classes.pageOtpCardBtn}
              color='primary'
              disabled={inactive}
              variant={selected ? 'contained' : 'outlined'}
              size='small'
              onClick={onClick}
            >
              {selected ? 'Selected' : 'Select'}
            </Button>
          </div>
        </div>
        {benefits && (
          <div className={classes.pageOtpCardBenefits}>
            <div dangerouslySetInnerHTML={{ __html: benefits as string }} />
          </div>
        )}
      </div>
    </div>
  );
}
