import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';
import MuiTextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { calculatePrice, ensureText, ensureUrl, setDateValue } from 'utils';
import { useForm, Controller } from 'react-hook-form';
import { CircularProgress, FormHelperText } from '@mui/material';
import { ControllerCheckbox } from 'components/ControllerCheckbox';
import { ReviewHouseholdMembers } from '../../HouseholdMembers';
import { DiscountBlock } from 'components/DiscountBlock';
import { UseUserEnrollPreview } from 'api/userEnrollInfo';
import { UsePreviewPayload } from 'hooks';
import { validationPattern } from 'utils/helpers/helpers';
import { PlanInterface } from 'api/userEnrollInfo';
import Collapse from '@mui/material/Collapse';
import InfoIcon from '@mui/icons-material/Info';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';

type Props = {
  classes: ClassNameMap;
  handleButtonNextStep: () => void;
  handleButtonPrevStep: () => void;
  handleOpenDonateDialog: () => void;
};

const checkBoxValues = [
  {
    value: '0',
    label: 'Not today',
  },
  {
    value: '25',
    label: '$25.00',
  },
  {
    value: '50',
    label: '$50.00',
  },
  {
    value: '100',
    label: '$100.00',
  },
  {
    value: 'other',
    label: 'Other amount',
  },
];

type DonateDefaultValuesType = {
  donate: string;
  checkedB: boolean;
  donate_other: number | null;
};

const donateDefaultValues = ({ donate, checkedB, donate_other }: DonateDefaultValuesType) => ({
  donate,
  checkedB,
  donate_other,
});

export function ReviewDetails({
  classes,
  handleButtonNextStep,
  handleButtonPrevStep,
  handleOpenDonateDialog,
}: Props): JSX.Element | null {
  const {
    state: {
      user,
      details,
      previewDetails: { enrollPreview, isLoading },
      selectedPlan,
      travelProtection,
    },
    dispatch,
  } = UseCheckoutDetails();

  const { data: info } = useMemberApi<MemberInfoOutput>('memberInfo');
  const payloadPreview = UsePreviewPayload({
    details,
    selectedPlan: selectedPlan!,
    info,
    user,
    selectedTp: travelProtection,
  });
  UseUserEnrollPreview(payloadPreview);

  const defaultValues = donateDefaultValues(details.donate);

  const { register, handleSubmit, watch, errors, control, trigger } = useForm({
    defaultValues,
  });

  const donateCheckbox = watch('donate');
  const donateInput = watch('donate_other');
  const checkedB = watch('checkedB');

  const isBtnDisabled = !!((donateCheckbox === 'other' && !donateInput) || !checkedB || Object.keys(errors).length);

  const onSubmit = (data: DonateDefaultValuesType) => {
    if (Object.keys(errors).length) return;

    const payload = { donate: data };
    dispatch({ type: 'SET_DETAILS', payload });
    handleButtonNextStep();
  };

  const [showDetails, setShowDetails] = useState(false);

  const { totalPriceConverted, subTotalHouseHolder, subTotalUserConverted } = calculatePrice(
    isLoading,
    enrollPreview.lines,
    donateCheckbox,
    !errors.donate_other ? (donateInput as number) : 0
  );

  if (isLoading || !enrollPreview)
    return (
      <div className={`${classes.reviewDetails} ${classes.box}`} style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`${classes.reviewDetails} ${classes.box}`}>
        <div className={classes.reviewDetailsMember}>
          <div className={`${classes.reviewDetail} ${classes.reviewDetailFirst}`}>
            <Typography component='h4' variant='h4'>
              {user.first_name} {user.last_name}
            </Typography>
            <span>Primary member</span>
          </div>

          <DiscountBlock classes={classes} user={enrollPreview.lines.primary} />

          <div className={classes.divider} />
          <div className={classes.reviewDetail}>
            <Typography component='h4' variant='h4'>
              Subtotal
            </Typography>
            <Typography component='h4' variant='h4'>
              {subTotalUserConverted}
            </Typography>
          </div>
          <Button size='small' onClick={handleButtonPrevStep}>
            Edit Plan
          </Button>
        </div>

        <ReviewHouseholdMembers subtotal={subTotalHouseHolder as number} handleButtonPrevStep={handleButtonPrevStep} />

        {!!info?.plan_type && (
          <div className={classes.reviewDetailsSubscriptionWrapper}>
            <Collapse in={showDetails} collapsedSize={16}>
              <div className={classes.reviewDetailsSubscription} onClick={() => setShowDetails((prev) => !prev)}>
                <InfoIcon className='icon' fontSize='inherit' />
                {!!selectedPlan &&
                  !selectedPlan?.one_time_purchase_product &&
                  (showDetails ? (
                    <>
                      Upon checkout, your current subscription will immediately be upgraded. Unused time on your current
                      plan and add-ons will be applied toward your new subscription. Any add-ons excluded from this
                      upgrade will remain active until the end of their current billing cycle.
                    </>
                  ) : (
                    'View subscription upgrade details.'
                  ))}
                {!!selectedPlan &&
                  selectedPlan?.one_time_purchase_product &&
                  (showDetails ? (
                    <>
                      Upon checkout, your current expiration date will be extended by {selectedPlan.period.number}{' '}
                      years. Add-ons excluded from this upgrade will remain active until their current expiration date.
                    </>
                  ) : (
                    'View upgrade details.'
                  ))}
                {/* {!!selectedPlan &&
                  'lifetime' === selectedPlan.type &&
                  (showDetails ? (
                    'year' === info.plan_type ? (
                      <>
                        Upon checkout, you will immediately be upgraded to a Lifetime Plan, and unused time on your
                        current plan will be applied toward your purchase. Existing add-ons carried over to your
                        Lifetime plan will continue their annual billing cycle, and NEW add-ons added to this plan will
                        be enrolled in the same annual billing cycle. Any add-ons excluded from this upgrade will remain
                        active until the end of their current billing cycle.
                      </>
                    ) : (
                      <>
                        Upon checkout, you will immediately be upgraded to a Lifetime Plan , and any add-ons added to
                        this plan will be enrolled in an annual subscription. Unused time on your current plan and
                        add-ons will be applied toward your new plan. Any add-ons excluded from this upgrade will remain
                        active until the end of their current billing cycle.
                      </>
                    )
                  ) : (
                    'View details.'
                  ))} */}
              </div>
            </Collapse>
          </div>
        )}

        <div className={classes.divider} />

        <div className={classes.reviewDetailsDonate}>
          <Typography component='h3' variant='h3'>
            Help us improve the lives of wounded veterans with the Gift of {ensureText('WeSalute+')}
          </Typography>
          <label className={classes.fieldLabel}>Your one-time contribution</label>
          <Controller
            name='donate'
            control={control}
            as={
              <MuiRadioGroup
                className={classes.reviewDonateRadios}
                row
                aria-label='donate'
                defaultValue={defaultValues.donate}
              >
                {checkBoxValues.map(({ value, label }) => (
                  <FormControlLabel key={value} value={value} control={<MuiRadio color='primary' />} label={label} />
                ))}
              </MuiRadioGroup>
            }
          />

          {donateCheckbox === 'other' && (
            <div className={`${classes.fieldWrapper} ${classes.reviewDonateAmount}`}>
              <label className={classes.fieldLabel}>Enter your contribution</label>
              <MuiTextField
                fullWidth
                name='donate_other'
                onChange={async () => await trigger('donate_other')}
                variant='outlined'
                defaultValue={defaultValues.donate_other}
                placeholder='$200'
                InputProps={{
                  inputProps: { min: 0, max: 999999 },
                }}
                type='number'
                error={!!errors.donate_other}
                inputRef={register({ required: true, pattern: validationPattern.donate_other })}
              />
              {errors.donate_other && <FormHelperText error>{errors.donate_other.message}</FormHelperText>}
            </div>
          )}

          <Button onClick={handleOpenDonateDialog}>Learn more about the Gift of {ensureText('WeSalute+')}</Button>
        </div>

        <div className={classes.divider} />

        <div className={classes.yourTotalToday}>
          <div className={`${classes.reviewDetail} ${classes.reviewDetailFirst}`}>
            <Typography component='h3' variant='h3'>
              Your total today:
            </Typography>
            <Typography component='h3' variant='h3' className={classes.yourTotalTodayPrice}>
              {totalPriceConverted}
            </Typography>
          </div>
          {!selectedPlan?.one_time_purchase_product && !!enrollPreview.subscription?.expires && (
            <div className={classes.reviewDetail}>
              <span>Your plan will autorenew on {setDateValue(enrollPreview.subscription.expires)}</span>
            </div>
          )}
        </div>
      </div>
      <FormGroup className={classes.reviewTerms} row>
        <ControllerCheckbox
          name='checkedB'
          required
          label={
            <>
              By purchasing a subscription, you agree to the{' '}
              <Button className='link' target='_blank' href={ensureUrl('https://www.wesalute.com/terms-conditions')}>
                Terms of Service
              </Button>{' '}
              and{' '}
              <Button className='link' target='_blank' href={ensureUrl('https://www.wesalute.com/privacy-policy')}>
                Privacy Policy
              </Button>{' '}
              of {ensureText('WeSalute (founded as Veterans Advantage)')}.
            </>
          }
          control={control}
        />

        {errors.checkedB && <FormHelperText error>*Please agree to our terms & conditions</FormHelperText>}
      </FormGroup>

      <div className={classes.actionsWrapper}>
        <Button type='submit' color='secondary' variant='contained' disabled={isBtnDisabled}>
          Continue to payment
        </Button>
      </div>
    </form>
  );
}
