import React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { UseStyles } from 'hooks';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { ensureText } from 'utils';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

const TravelProtectionDialog = (props: SimpleDialogProps): JSX.Element => {
  const classes = UseStyles();
  const { open, onClose } = props;

  return (
    <Dialog
      scroll='body'
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.dialog + ' ' + classes.travelProtectionDialog }}
      aria-labelledby='customized-dialog-title'
    >
      <div className={classes.dialogTitle} id='customized-dialog-title'>
        <IconButton className={classes.dialogTitleClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography component='h2' variant='h2'>
          TravelProtection™
        </Typography>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Typography component='h2' variant='h2' className={classes.dialogSubTitle}>
          Add TravelProtection™ for Safer Travel 365 Days a Year
        </Typography>
        <div className={classes.travelProtectionDialogText}>
          Get peace of mind when traveling more than 100 miles from home for any reason, including business, vacation
          travel or just to visit friends and family!
          <br />
          <br />
          Every day of the year, anytime you are more than 100 miles from your home, whether driving or flying, and
          experience an emergency, you can be safely covered with TravelProtection™.
          <br />
          <br />
          TravelProtection™ covers you with an outstanding bundle of medical insurance and travel assistance services
          backed by A+ rated insurance underwriter:
          <br />
          <br />
          <ul>
            <li>$50,000 Medevac Insurance</li>
            <li>$5,000 AD&D Insurance</li>
            <li>24-Hour Medical Assistance</li>
            <li>Worldwide Travel Assistance</li>
            <li>Personal Security Assistance</li>
            <li>Emergency Travel Assistance</li>
          </ul>
          <br />
          <br />
          With a WeSalute+ subscription of 1 year or longer, you are eligible for TravelProtection™ at the low WeSalute+
          rate of $39.98 annually.
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button autoFocus onClick={onClose} color='primary' variant='outlined'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TravelProtectionDialog;
