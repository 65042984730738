import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ClassNameMap } from '@mui/styles/withStyles';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { ensureText, setDateValue, withPathPrefix } from '../../../utils';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import container from 'services/container';
import {
  OffersNewIcon,
  CareerNetworkIcon,
  RxIcon,
  WsIdIcon,
  VipAssistanceIcon,
  NewsIcon,
  SaveOnTpIcon,
  FamilyIcon,
  ChatIcon,
} from 'theme/icons';
import { Kustomer } from 'containers/Widget/Kustomer';
import { processAppUrl } from 'utils/appshell';
import EventManager from 'services/EventManager';

type Props = {
  branches: Record<string, string>;
  classes: ClassNameMap;
};

const benefitsItems: Array<{
  icon: JSX.Element;
  label: string;
}> = [
  {
    icon: <OffersNewIcon />,
    label: 'Over 500 WeSalute+ Offers',
  },
  {
    icon: <CareerNetworkIcon />,
    label: 'Jobs & Career Network',
  },
  {
    icon: <RxIcon />,
    label: 'Prescription Drug Discount',
  },
  {
    icon: <WsIdIcon />,
    label: 'Secure WeSalute+ ID Card',
  },
  {
    icon: <VipAssistanceIcon />,
    label: 'VIP Member Assistance',
  },
  {
    icon: <NewsIcon />,
    label: 'Military News & Resources',
  },
  {
    icon: <SaveOnTpIcon />,
    label: 'Save 50% on Travel Protection',
  },
  {
    icon: <FamilyIcon />,
    label: 'Add up to 4 Household Members',
  },
];

export function SubscriptionDetails({ classes, branches }: Props): JSX.Element | null {
  const {
    state: { subscriptionDetails, config, brand_queue, user },
  } = UseCheckoutDetails();
  const redirectTo = (path: string) => () => {
    const appshell = container.get('appshell');
    if (appshell && appshell.goto) {
      appshell.goto(path);
    }
  };
  const { data: info, mutate } = useMemberApi<MemberInfoOutput>('memberInfo');
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [iframe, setIframe] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const returnTo = urlParams.has('return_to') ? decodeURIComponent(urlParams.get('return_to')!) : null;
  const returnToOffer =
    returnTo && (/\.wesalute.com\/offers\//.test(returnTo) || /\.wesalute.com\/node\/[0-9]+/.test(returnTo))
      ? returnTo
      : null;
  const pageUrl = window.location.href;
  useEffect(() => {
    mutate();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('return_to') && urlParams.has('iframe')) {
      setRedirectUrl(urlParams.get('return_to')!);
      setTimeout(() => {
        window.location.href = decodeURIComponent(urlParams.get('return_to')!);
      }, 5000);
    }
    if (urlParams.has('iframe')) {
      setIframe(true);
    }
  }, []);
  if (!subscriptionDetails || !info) return null;
  const { first_name, last_name, email, member_id, plan_expires, military } = info;
  const expires = subscriptionDetails?.user?.subscription?.expires || plan_expires;
  const newHhmAdded = user.household_members.some((item) => item.new && item.email);
  return (
    <>
      {!!redirectUrl && (
        <div className={classes.thankYouReceipt}>
          {iframe
            ? 'You will be redirected back in 5 seconds...'
            : `You will be redirected back to ${redirectUrl} in 5 seconds...`}
        </div>
      )}
      <div className={classes.thankYouReceipt}>
        Receipt will be sent to <b>{email}</b>
      </div>
      {newHhmAdded && (
        <div className={classes.thankYouReceiptHHM}>
          If you added a new Household Member with an email address, an email has been sent to them as well.
        </div>
      )}
      <div className={classes.thankYouCardWrapper}>
        {!config.vetrewards_card_hide && (
          <div className={classes.checkoutWesaluteCardImg}>
            <img
              className='img'
              src={withPathPrefix('/images/card-wesalute-shadow.svg')}
              alt={`${ensureText('WeSalute+')} Card`}
            />
            <div className={classes.checkoutWesaluteCardName}>
              <span>
                {first_name} {last_name}
              </span>
              <br />
              {!!military.branch && military.branch.name}
            </div>
            <div className={classes.checkoutWesaluteCardId}>
              <span>Member ID</span>
              <br />
              {member_id}
            </div>
            <div className={classes.checkoutWesaluteCardDate}>
              {expires && (
                <>
                  <span>VALID UNTIL</span>
                  <br />
                  {setDateValue(expires)}
                </>
              )}
            </div>
          </div>
        )}
        <div className={classes.thankYouBenefits}>
          <div className={classes.thankYouBenefitsTitle}>Discover and Enjoy Your WeSalute+ Premium Benefits</div>
          <div className={classes.thankYouBenefitsListWrapper}>
            <ul className={classes.thankYouBenefitsList}>
              {benefitsItems.map((item) => (
                <li key={item.label}>
                  {React.cloneElement(item.icon, { className: 'icon' })}
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {!!brand_queue && !!brand_queue.brands.length && (
        <div className={classes.thankYouBrands}>
          {brand_queue.title && <div className={classes.thankYouBrandsTitle}>{brand_queue.title}</div>}
          <div className={classes.thankYouBrandsListWrapper}>
            <ul className={classes.thankYouBrandsList}>
              {brand_queue.brands.map((item, index) => (
                <li key={`item-${index}`} className={`brand-item-${index}`}>
                  <a
                    href={item?.url}
                    target={'_blank'}
                    rel={'noreferrer'}
                    onClick={() => {
                      EventManager.dispatch('cta.clicked', {
                        label: item?.image?.alt,
                        action: item?.action,
                        type: 'banner',
                        url: pageUrl,
                      });
                    }}
                  >
                    <img src={item?.image?.href} alt={item?.image?.alt} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {!iframe && (
        <div className={`${classes.thankYouCardBtns} ${classes.noPrint}`}>
          {returnToOffer ? (
            <Button
              href={returnToOffer}
              color='primary'
              variant='contained'
              className={classes.thankYouCardBtnGold}
              onClick={() => {
                EventManager.dispatch('cta.clicked', {
                  label: 'Return to Offer Page to Redeem',
                  action: 'goto-offer',
                  type: 'button',
                  url: pageUrl,
                });
              }}
            >
              Return to Offer Page to Redeem
            </Button>
          ) : (
            <>
              <Button
                href={processAppUrl('marketing/marketplace') ?? '/'}
                color='primary'
                variant='contained'
                className={classes.thankYouCardBtnGold}
                onClick={() => {
                  EventManager.dispatch('cta.clicked', {
                    label: 'Browse the Marketplace',
                    action: 'goto-marketplace',
                    type: 'button',
                    url: pageUrl,
                  });
                }}
              >
                Browse the Marketplace
              </Button>

              <Kustomer
                buttonText={
                  <>
                    <ChatIcon className='icon' />
                    Get a VIP walkthrough
                  </>
                }
                className={classes.thankYouCardBtn}
                color='primary'
                variant='outlined'
                onClick={() => {
                  EventManager.dispatch('cta.clicked', {
                    label: 'Get a VIP walkthrough',
                    action: 'get-walkthrough',
                    type: 'button',
                    url: pageUrl,
                  });
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
