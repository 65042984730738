import React, { useState, useEffect } from 'react';
import { UsePreviewPayload, UseStyles } from 'hooks';
import { FormProvider } from 'react-hook-form';
import Button from '@mui/material/Button';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import CircularProgress from '@mui/material/CircularProgress';
import { TakeOffIcon } from 'theme/icons';
import { useStoreon } from 'storeon/react';
import { useForm } from 'hooks/useForm';
import CheckoutApi from 'services/api/checkout';
import { useMemberApi } from 'hooks/useMemberApi';
import { Checkbox, CheckboxGroup } from 'components/Form';
import { TooltipDialog } from 'containers/Dialog';
import { UseUserEnrollPreview, useEnrollPreviewCallback } from 'api/userEnrollInfo';
import { useCheckoutApi } from 'hooks/useCheckoutApi';
import {
  MemberInfoOutput,
  PurchaseBriefLineItemsGroupOutput,
  MemberSubscriptionAddonPreviewOutput,
  MemberSubscriptionInfoOutput,
  PurchaseBriefLineItemOutput,
  PurchaseBriefLineItemsInfoOutput,
  PurchaseBriefPlanOutputTypeEnum,
} from 'services/api/client';
import { ensureText, formatDateDiff, fromCentsFixed, getConvertedPrice, setDateValue } from 'utils';
import InfoIcon from '@mui/icons-material/Info';
import { UseCheckoutDetails } from '../../../context/checkoutDetails.context';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useDialog } from 'hooks/useDialog';
import plansList from 'data/plans.json';

export interface SimpleDialogProps {
  onClose: (success?: boolean) => void;
  onOpen?: () => void;
}

export function AddTravelProtectionDialog(props: SimpleDialogProps): JSX.Element {
  const classes = UseStyles();
  const { isOpen, context = {} } = useDialog('manageTravelProtection');
  const { onClose, onOpen } = props;
  const [completed, setCompleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [primaryChecked, setPrimaryChecked] = useState(true);
  const [hhmLines, setHhmLines] = useState<null | PurchaseBriefLineItemsInfoOutput>(null);
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const {
    dispatch,
    state: { user, details, selectedPlan, travelProtection },
  } = UseCheckoutDetails();
  const { household_members, travel_protection } = user;
  const { handleSubmit, rhkMethods } = useForm({
    handle: async (data: Data, { setError }) => {
      setSubmitting(true);
    },
    onSuccess: async () => {
      setCompleted(true);
    },
    onFinally: async () => {
      setSubmitting(false);
    },
  });
  const { setValue } = rhkMethods;
  const enrollPreview = useEnrollPreviewCallback();
  useEffect(() => {
    isOpen && onOpen && onOpen();
  }, [isOpen]);
  useEffect(() => {
    (async () => {
      if (selectedPlan?.id && user.travel_protection) {
        const { id, parentPlan, one_time_purchase_product } = selectedPlan;
        const otp = one_time_purchase_product && parentPlan;
        const payload = {
          plan: otp && parentPlan ? parentPlan : id,
          subscription_extension: otp ? id : undefined,
          travel_protection: user.travel_protection && user.travel_protection.id,
          household_members: user.household_members
            .slice(0, 4)
            .filter(({ expires }) => !expires || expires.subscription !== expires.travel_protection)
            .map((member) => ({ ...member, travel_protection: true, id: member.new ? null : member.id })),
          contribution: 0,
        };
        const { lines } = (await enrollPreview(payload)) as unknown as MemberSubscriptionAddonPreviewOutput;
        setHhmLines(lines);
      }
    })();
  }, [selectedPlan?.id, user.travel_protection?.id, household_members.length]);
  const _default = household_members
    .filter(
      ({ active, travel_protection, expires }) =>
        (!data?.travel_protection && active) || !expires || expires.subscription === expires.travel_protection
    )
    .map(({ id }) => id!);
  const [hhmChecked, setHhmChecked] = useState<null | string[]>(null);
  useEffect(() => {
    !travelProtection && setHhmChecked(_default);
  }, []);
  useEffect(() => {
    if (isOpen) {
      setPrimaryChecked(true);
      !!travelProtection && setHhmChecked(travelProtection);
    }
  }, [isOpen]);
  useEffect(() => {
    !primaryChecked && setValue('household_members', []);
  }, [primaryChecked]);
  useEffect(() => {
    !!hhmChecked &&
      dispatch({
        type: 'SET_MEMBERS_TRAVEL_PROTECTION',
        payload: hhmChecked,
      });
  }, [hhmChecked]);
  const getTpPrice = (id: string, index: number) => {
    const lines = [hhmLines?.primary, ...(hhmLines?.household_members ?? [])];
    const group = lines.find((line) => line?.user.id === id) ?? lines[index];
    return group?.items.find((item) => item.type === 'travel_protection')?.price.amount ?? 0;
  };
  const primaryPrice = getTpPrice('primary', 0);
  // const hhmPrice = hhmLines?.household_members[0]?.total.amount ?? 0;
  const [showMore, setShowMore] = useState(false);
  const expiresIn = formatDateDiff(data?.plan_expires ?? Date.now());
  const tpTotalPrice = primaryChecked
    ? household_members.reduce((prev, value, index) => {
        return prev + (travelProtection?.includes(value.id) ? getTpPrice(value.id, index + 1) : 0);
      }, primaryPrice)
    : 0;
  const updateHhm = (target: string) => {
    let value = [];
    const checked = hhmChecked ?? [];
    if (checked.includes(target)) {
      value = checked.filter((id) => id !== target);
    } else {
      value = [...checked, target];
    }
    setHhmChecked(value);
  };
  if (!selectedPlan) {
    return <></>;
  }
  const { type, period } = selectedPlan;
  const plans = plansList as Record<PurchaseBriefPlanOutputTypeEnum, any>;
  const periodType = `${period.number}${period.unit}` as PurchaseBriefPlanOutputTypeEnum;
  const planConfig = type && period ? plans[periodType] || plans[type] : null;
  const travel = selectedPlan.travel_protection[0];
  const hasSubscription = !selectedPlan.one_time_purchase_product;
  const price = travel.price.amount || 0;
  const intervalMap: { [k: string]: string } = { month: 'mo', year: 'yr' };
  const priceSuffix = hasSubscription ? `/${intervalMap[period.unit] ?? period.unit}` : '';
  const tpPriceLine = `${getConvertedPrice(period.number * price)}${priceSuffix}`;
  return (
    <>
      <Dialog
        scroll='body'
        open={isOpen}
        onClose={() => onClose(primaryChecked)}
        fullWidth
        maxWidth={false}
        classes={{ paper: classes.dialog + ' ' + classes.addTravelProtectionDialog }}
        aria-labelledby='customized-dialog-title'
      >
        <div className={classes.dialogTitle} id='customized-dialog-title'>
          <IconButton className={classes.dialogTitleClose} onClick={() => onClose(primaryChecked)}>
            <CloseIcon />
          </IconButton>
          <Typography component='h2' variant='h2'>
            Add Travel Protection
          </Typography>
        </div>
        <DialogContent className={classes.dialogContent}>
          <FormProvider {...rhkMethods}>
            <form onSubmit={handleSubmit}>
              <div className={classes.accountDialogAddTravelProtection}>
                {data?.plan_expires && (
                  <>
                    <div className={classes.accountDialogAddTravelProtectionInfo}>
                      {household_members.length ? (
                        <>
                          Add Travel Protection for your household members for $39.98 per person for 1 year of
                          protection.
                        </>
                      ) : (
                        <>Add Travel Protection for $39.98 per person for 1 year of protection.</>
                      )}
                    </div>
                    <div className={classes.accountDialogAddTravelProtectionInfoFootnote}>
                      <InfoIcon className='icon' />
                      <div>
                        <p>
                          *You have {expiresIn} remaining on your Annual Plan. NEW Travel Protection plans will be
                          prorated to {expiresIn} to ensure the expiration/renewal date is synced with your membership
                          plan.
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className={classes.accountDialogAddTravelProtectionTitle}>
                  Primary Member: {data?.first_name} {data?.last_name}
                </div>
                <div className={classes.accountDialogAddTravelProtectionInfo}>
                  <TooltipDialog
                    element={({ toggle }) => (
                      <div>
                        <Checkbox
                          noMargin
                          checked={primaryChecked}
                          label='Add Travel Protection'
                          name='primary'
                          onClick={() => (primaryChecked ? toggle() : setPrimaryChecked(true))}
                        />
                      </div>
                    )}
                    render={({ toggle }) => (
                      <>
                        <div className={classes.accountTooltipContent}>
                          If you remove Travel Protection for yourself, you will not be able to add travel protection
                          for for your household members.
                          <br />
                          <br />
                          Are you sure you want to remove Travel Protection?
                        </div>
                        <div className={classes.accountTooltipActions}>
                          <Button className={`${classes.accountTooltipAction} no`} onClick={() => toggle()}>
                            No
                          </Button>
                          <Button
                            className={`${classes.accountTooltipAction} yes`}
                            onClick={() => {
                              setPrimaryChecked(false);
                              toggle();
                            }}
                            // onClick={() => {
                            //   storeonDispatch('dialog/close', { id: 'addTravelProtection' });
                            // }}
                          >
                            Yes
                          </Button>
                        </div>
                      </>
                    )}
                  />
                  ${fromCentsFixed(primaryPrice)}
                </div>
                <div className={classes.accountDialogAddTravelProtectionDivider} />
                {!!household_members.length && (
                  <>
                    {household_members.map(
                      ({ id, first_name, last_name, travel_protection, active, expires }, index) => {
                        const checkbox = (toggle?: () => void) => (
                          <>
                            <Checkbox
                              checked={!!hhmChecked?.includes(id)}
                              disabled={!primaryChecked}
                              noMargin
                              label={`${first_name} ${last_name}`}
                              name={`_hhm[${id}]`}
                              onClick={(e) => {
                                if (active && travel_protection && hhmChecked?.includes(id)) {
                                  !!toggle && toggle();
                                } else {
                                  updateHhm(id);
                                }
                              }}
                            />
                            <div className={classes.addTravelProtectionDialogHouseholdDescription}>
                              <span>
                                {ensureText(hasSubscription ? 'WeSalute+' : planConfig.title)} TravelProtection (
                                {tpPriceLine})
                              </span>
                              {data?.plan_expires &&
                                !['trial', 'month'].includes(data.plan_type) &&
                                (!travel_protection || data.plan_expires !== expires?.travel_protection) && (
                                  <span>
                                    Prorated TravelProtection for remaining plan days -{' '}
                                    {formatDateDiff(
                                      data?.plan_expires ?? Date.now(),
                                      expires?.travel_protection ?? Date.now()
                                    )}
                                  </span>
                                )}
                            </div>
                          </>
                        );
                        return (
                          <div key={id} className={classes.accountDialogAddTravelProtectionItemValue}>
                            {active && travel_protection ? (
                              <TooltipDialog
                                element={({ toggle }) => <div>{checkbox(toggle)}</div>}
                                render={({ toggle }) => (
                                  <>
                                    <div className={classes.accountTooltipContent}>
                                      This member has Travel Protection on their current plan.
                                      <br />
                                      If you choose not to stack this household member’s TravelProtection, their current
                                      Travel Protection Plan will remain active until it expires on{' '}
                                      {setDateValue(expires?.travel_protection ?? 0, false, { month: 'long' })}.
                                      <br />
                                      <br />
                                      Are you sure you want to remove Travel Protection for this household member?
                                    </div>
                                    <div className={classes.accountTooltipActions}>
                                      <Button
                                        className={`${classes.accountTooltipAction} no`}
                                        onClick={(e) => {
                                          toggle();
                                        }}
                                      >
                                        No
                                      </Button>
                                      <Button
                                        className={`${classes.accountTooltipAction} yes`}
                                        onClick={() => {
                                          toggle();
                                          updateHhm(id);
                                        }}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </>
                                )}
                              />
                            ) : (
                              <div>{checkbox()}</div>
                            )}
                            ${fromCentsFixed(getTpPrice(id, index + 1))}
                          </div>
                        );
                      }
                    )}
                    <div className={classes.accountDialogAddTravelProtectionDivider} />
                  </>
                )}
                <div className={classes.accountDialogAddTravelProtectionTotal}>
                  <span>Total today:</span>${fromCentsFixed(tpTotalPrice)}
                </div>
                <div className={classes.dialogActions}>
                  <Button
                    className={classes.accountDialogAddTravelProtectionBtn}
                    disabled={submitting}
                    onClick={() => onClose(primaryChecked)}
                    color='primary'
                    variant='contained'
                  >
                    Continue
                    {submitting && <CircularProgress size={24} />}
                  </Button>
                </div>
              </div>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
