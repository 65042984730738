import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useForm } from 'react-hook-form';
import { PaymentOption } from 'components/PaymentOption';
import {
  AddressInterface,
  postUserEnroll,
  UserDataInterface,
  UserEnrollInterface,
  UseUserEnrollPreview,
} from 'api/userEnrollInfo';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { AddressBlock } from 'components/AddressBlock';
import {
  defaultAddress,
  defaultBillingAddress,
  setInitialAdresss,
  handleAddressFieldsMod,
  DefaultBillingAddressType,
} from './helpers';
import { ControllerCheckbox } from 'components/ControllerCheckbox';
import { useMemberApi } from 'hooks/useMemberApi';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { putShippingPaymentInfo } from 'api/shippingPaymentInfo';
import { ensureText, getConvertedPrice, setDateValue } from 'utils';
import { ButtonGroup, CircularProgress } from '@mui/material';
import { StripeCardElement, Token, PaymentRequest } from '@stripe/stripe-js';
import { UsePreviewPayload } from 'hooks';
import { AddressInput, MemberInfoOutput, ShippingPaymentInfoUpdateInput } from 'services/api/client';
import { AxiosResponse } from 'axios';
import { Account, HttpClient } from 'services';
import { CanMakePaymentResult } from '@stripe/stripe-js';

type Props = Partial<UserEnrollInterface> & {
  classes: ClassNameMap;
  handleButtonNextStep: () => void;
};

export type DefaultValuesType = AddressInterface &
  DefaultBillingAddressType & {
    rank: string;
    billingCheck: boolean;
    billing_name: string;
    card_token: undefined | string;
  };

export function CompleteInfo({ ranks, classes, handleButtonNextStep }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const {
    state: {
      details,
      selectedPlan,
      user,
      previewDetails: {
        enrollPreview: { lines, subscription },
        isLoading: isValidating,
      },
      config,
      travelProtection,
    },
    dispatch,
  } = UseCheckoutDetails();

  const stripe = useStripe();
  const elements = useElements();

  const defaultValues = {
    rank: user.military?.rank || '',
    billingCheck: true,
    cardCheck: false,
    noCharge: false,
    billing_name: `${user.first_name} ${user.last_name}`,
    card_token: undefined,
    ...setInitialAdresss(user.address, defaultAddress),
    ...setInitialAdresss(user.billing && user.billing.address, defaultBillingAddress, true),
  };
  const { getValues, setValue, register, handleSubmit, watch, control, errors, setError, clearErrors, trigger } =
    useForm({
      defaultValues,
    });

  const typeCheckbox = watch('type');
  const billingTypeCheckbox = watch('billing_type');
  const isBilling = watch('billingCheck');
  const isCardChecked = watch('cardCheck');
  const idNoChargeChecked = watch('noCharge');
  const isPost = typeCheckbox === 'post';
  const isBillingPost = billingTypeCheckbox === 'post';

  const isNoCharge = config.payment_info_exclude_at_no_charge && !lines.total.amount;
  const adminNoCharge = Account.roleAdmin() && !!idNoChargeChecked;
  const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest>(null);
  const [payWithWallet, setPayWithWallet] = useState(false);
  const [enabledWalletMethod, setEnabledWalletMethod] = useState('');
  const { data: info } = useMemberApi<MemberInfoOutput>('memberInfo');
  const payload = UsePreviewPayload({
    details,
    selectedPlan: selectedPlan!,
    user,
    selectedTp: travelProtection,
    info,
    noCharge: !!idNoChargeChecked,
  });
  const { mutate } = UseUserEnrollPreview(payload);

  useEffect(() => {
    if (stripe && lines.total.amount > 0) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: lines.total.amount,
        },
        requestPayerName: true,
        wallets: ['applePay', 'googlePay'],
      });
      pr.canMakePayment().then((result) => {
        if (result && (result.applePay || result.googlePay)) {
          setPaymentRequest(pr);
          setEnabledWalletMethod(result.applePay ? 'apple-pay' : 'google-pay');
        }
      });
      pr.on('token', async (event) => {
        const { token } = event;
        event.complete('success');
        setIsLoading(true);
        const data = getValues() as DefaultValuesType;
        await processPayment(data, token.id);
      });
    }
  }, [stripe, lines.total.amount]);

  useEffect(() => {
    if (Account.roleAdmin() && !isValidating) {
      mutate();
    }
  }, [adminNoCharge]);

  useEffect(() => {
    if (isCardChecked && user && user.card)
      dispatch({ type: 'S_PAYMENT_INFO_ENTERED', payload: { paymentMethod: user.card.brand, step: 2 } });
  }, [dispatch, isCardChecked, user]);

  const onSubmit = async (data: DefaultValuesType) => {
    if (Object.keys(errors).length || !stripe || !elements) return;
    if (payWithWallet && !isNoCharge && !adminNoCharge && paymentRequest) {
      paymentRequest.show();
      return;
    }
    setIsLoading(true);
    let stripeToken: Token | undefined;
    const address = handleAddressFieldsMod('home', data) as AddressInput;
    const billingAddress = handleAddressFieldsMod('post', data) as AddressInput;
    if (!isCardChecked && !isNoCharge && !adminNoCharge) {
      const cardElement = elements.getElement(CardElement);
      const { line1, line2, postal_code, city, state } = data.billingCheck ? address : billingAddress;
      const { error, token } = await stripe.createToken(cardElement as StripeCardElement, {
        name: data.billing_name ?? '',
        address_line1: line1,
        address_line2: line2 ?? '',
        address_city: city,
        address_state: state,
        address_zip: postal_code,
        address_country: 'US',
      });
      stripeToken = token;
      if (error) {
        setError(`card_token`, {
          type: 'manual',
          message: error.message,
        });
        return setIsLoading(false);
      }
    }
    await processPayment(data, stripeToken && stripeToken.id);
  };
  const processPayment = async (data: DefaultValuesType, card_token?: null | string) => {
    if (Object.keys(errors).length || !stripe || !elements) return;
    const address = handleAddressFieldsMod('home', data) as AddressInput;
    const billingAddress = handleAddressFieldsMod('post', data) as AddressInput;
    const shippingPayload: ShippingPaymentInfoUpdateInput = {
      address,
      billing: !isNoCharge ? { address: data.billingCheck ? address : billingAddress } : null,
      rank: data.rank,
      card_token,
    };
    try {
      await putShippingPaymentInfo(shippingPayload);
      const data = await postUserEnroll(payload, info!, user, selectedPlan!);
      dispatch({ type: 'SET_SUBSCRIPTION_DETAILS', payload: data as UserDataInterface });
      setIsLoading(false);
      handleButtonNextStep();
    } catch (e) {
      const error = e as AxiosResponse<HttpResponseError>;
      if (!error.data) {
        throw e;
      }
      const { details: { field = '' } = {}, type = 'unknown', message = '' } = error.data.error ?? {};
      const namespace = type.split('.')[0];
      if (['payment', 'subscription'].includes(namespace)) {
        setError('card_token', {
          type: 'manual',
          message,
        });
      } else {
        const _field = field
          .replace(/\.address\./, '')
          .replace(/\.billing_address\./, '')
          .replace(/\./, '');
        if (_field) {
          setError(`${_field}` as keyof AddressInterface, {
            type: 'manual',
            message,
          });
        }
      }
      setIsLoading(false);
    }
  };
  const contributionPrice = lines?.contribution[0] ? lines.contribution[0].price.amount : 0;
  return (
    <form>
      <div className={classes.completeInformation}>
        <div className={`${classes.box} ${classes.completeInformationBlock}`}>
          <Typography component='h3' variant='h3'>
            Enter your shipping address
          </Typography>

          <AddressBlock
            setValue={setValue}
            getValues={getValues}
            isPost={isPost}
            defaultValues={defaultValues}
            classes={classes}
            control={control}
            errors={errors}
            register={register}
            ranks={ranks}
            trigger={trigger}
            isRanks
          />
        </div>
        {!isNoCharge && (
          <>
            <div className={`${classes.box} ${classes.completeInformationBlock}`}>
              <Typography component='h3' variant='h3'>
                Enter your billing address
              </Typography>

              <FormGroup className={classes.fieldWrapper}>
                <ControllerCheckbox
                  label='My billing address is the same as my shipping address'
                  name='billingCheck'
                  control={control}
                />
              </FormGroup>

              {!isBilling && (
                <AddressBlock
                  setValue={setValue}
                  getValues={getValues}
                  isBilling
                  isPost={isBillingPost}
                  defaultValues={defaultValues}
                  classes={classes}
                  control={control}
                  errors={errors}
                  register={register}
                  trigger={trigger}
                />
              )}
            </div>

            <PaymentOption
              setPayWithWallet={setPayWithWallet}
              card={user.card}
              isCardChecked={isCardChecked}
              idNoChargeChecked={idNoChargeChecked}
              isPayingWithWallet={!!payWithWallet}
              enabledWalletMethod={enabledWalletMethod}
              control={control}
              register={register}
              errors={errors}
              clearErrors={clearErrors}
              CardElement={CardElement}
            />
          </>
        )}

        <div className={`${classes.box} ${classes.completeInformationBlock}`}>
          <Typography component='h3' variant='h3'>
            Your plan
          </Typography>
          <div className={`${classes.reviewDetail} ${classes.reviewDetailFirst}`}>
            <div>{ensureText('WeSalute+')} Plan Total</div>
            {!isValidating && getConvertedPrice(lines.total.amount - contributionPrice)}
          </div>
          <div className={`${classes.reviewDetail} ${classes.reviewDetailFirst}`}>
            <div>Wounded Gift of {ensureText('WeSalute+')}</div>
            {!isValidating && contributionPrice ? getConvertedPrice(contributionPrice) : '$0.00'}
          </div>
          <div className={classes.divider} />
          <div className={classes.yourTotalToday}>
            <div className={`${classes.reviewDetail} ${classes.reviewDetailFirst}`}>
              <Typography component='h3' variant='h3'>
                Your total today:
              </Typography>
              <Typography component='h3' variant='h3' className={classes.yourTotalTodayPrice}>
                {!isValidating && getConvertedPrice(lines.total.amount)}
              </Typography>
            </div>
            {!selectedPlan?.one_time_purchase_product && !!subscription?.expires && (
              <div className={classes.reviewDetail}>
                <span>
                  Your {ensureText('WeSalute+')} plan will autorenew on {setDateValue(subscription.expires)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.actionsWrapper}>
        <ButtonGroup>
          <Button
            color='secondary'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            type='button'
            disabled={!!Object.keys(errors).length || isLoading || isValidating}
            style={{ minWidth: '140px' }}
          >
            {!isLoading ? 'Submit' : <CircularProgress size={22} color={'secondary'} />}
          </Button>
        </ButtonGroup>
      </div>
    </form>
  );
}
