import { BaseSyntheticEvent, useState } from 'react';
import { useForm as useHookForm, get, UseFormMethods, ValidationMode } from 'react-hook-form';
import { AxiosResponse } from 'axios';

const { zodResolver } = require('@hookform/resolvers/zod');

type Props = {
  schema?: any;
  mode?: keyof ValidationMode;
  checkDirty?: Array<string | boolean>;
  handle: (data: Data, methods: UseFormMethods) => void;
  onError?: () => void;
  onSuccess?: () => void;
  onFinally?: () => void;
};

type ReturnData = {
  dirty: boolean;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  serverErrors: Data;
  rhkMethods: any;
};

export const useForm = ({
  mode = 'onSubmit',
  schema,
  checkDirty,
  handle,
  onError,
  onSuccess,
  onFinally,
}: Props): ReturnData => {
  const [serverErrors, setServerErrors] = useState<Data>({});
  const methods = useHookForm({
    ...(schema ? { resolver: zodResolver(schema) } : {}),
    mode,
  });
  const {
    errors,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = methods;
  const isDirtyFields = (fields: Array<string | boolean>) => {
    for (const item of fields) {
      if (!item) {
        return false;
      }
      if (true === item) {
        continue;
      }
      if (!get(dirtyFields, item)) {
        return false;
      }
    }
    return true;
  };
  const onSubmit = async (data: Data) => {
    try {
      await handle(data, methods);
      if (onSuccess) {
        await onSuccess();
      }
    } catch (e) {
      const error = e as AxiosResponse<HttpResponseError>;
      if (!error.data) {
        throw e;
      }
      const { details: { constraint = '', field = '' } = {}, message = '' } = error.data.error ?? {};
      setServerErrors({
        constraint,
        field,
        message,
      });
      if (field) {
        setError(field.replace(/\./, ''), {
          type: 'manual',
          message,
        });
      }
      if (onError) {
        await onError();
      }
    }
    if (onFinally) {
      await onFinally();
    }
  };
  return {
    dirty: checkDirty ? isDirtyFields(checkDirty) : false,
    handleSubmit: handleSubmit(onSubmit),
    serverErrors,
    rhkMethods: methods,
  };
};
