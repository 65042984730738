import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { UseStyles } from 'hooks';
import { HouseholdMemberInfo } from 'api/userEnrollInfo';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { fromCentsFixed } from 'utils';
import { CircularProgress } from '@mui/material';
import { dateDiff2Unit, period2Months, planRetailPrice } from 'utils/helpers/helpers';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';

type Props = {
  index: number;
  handleRemoveHouseholdMember: (arg0: number) => void;
  member: HouseholdMemberInfo;
};

export function ActiveHouseholdMember({ index, member, handleRemoveHouseholdMember }: Props): JSX.Element {
  const classes = UseStyles();
  const {
    state: {
      selectedPlan,
      previewDetails: { enrollPreview, error, isLoading },
    },
  } = UseCheckoutDetails();
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const { active, first_name, last_name, email = '', relationship, expires } = member;
  const lines =
    enrollPreview.lines.household_members[index]?.items.filter(({ type }) =>
      ['plan', 'subscription_extension'].includes(type)
    ) ?? [];
  const planMonths = selectedPlan ? period2Months(selectedPlan.period) : 0;
  const proratedMonths =
    data?.plan_expires && !['trial', 'month'].includes(data.plan_type)
      ? dateDiff2Unit('month', data?.plan_expires, expires?.subscription)
      : 0;
  const months = planMonths + proratedMonths;
  const price = lines.reduce((prev, current) => prev + current.price.amount, 0);
  return (
    <div className={classes.membersDialogAddedItem}>
      {!active && (
        <IconButton onClick={() => handleRemoveHouseholdMember(index)} className={classes.membersDialogAddedClose}>
          <CloseIcon className='icon' />
        </IconButton>
      )}
      <div className={classes.membersDialogAddedName}>
        {first_name} {last_name}
      </div>
      <div className={classes.membersDialogAddedRelationship}>{relationship}</div>
      <div className={classes.membersDialogAddedEmail}>{email}</div>
      <div className={classes.membersDialogAddedPrice}>
        {isLoading ? (
          <CircularProgress size={18} />
        ) : (
          <>
            {!selectedPlan?.trial && (
              <>
                <span>${!!selectedPlan && fromCentsFixed(planRetailPrice(months))}</span>&nbsp;&nbsp;
              </>
            )}
            ${fromCentsFixed(price)}
          </>
        )}
      </div>
    </div>
  );
}
